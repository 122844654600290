var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GridItem',{staticClass:"small",attrs:{"colidx":_vm.col,"height":_vm.height}},[_c('div',{staticClass:"clickable-area",on:{"click":function($event){return _vm.clickevt(_vm.event)}}}),_vm._v(" "),(_vm.loaded)?_c('div',{staticClass:"gradient-overlay",style:(_vm.elevated && _vm.event.public === false
                    ? ''
                    : 'background: linear-gradient(-35deg, ' +
                      _vm.event.rgba +
                      ' 0%, rgba(25, 22, 32,0) 100%);')}):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('div',{staticClass:"calendar-container"},[(_vm.event.image)?_c('figure',{staticClass:"hero-img"},[_c('nuxt-img',{attrs:{"loading":"lazy","src":_vm.event.image,"alt":_vm.event.title}})],1):_vm._e(),_vm._v(" "),_c('GridBody',{staticClass:"card-body"},[_c('div',{staticClass:"card-block"},[(_vm.elevated)?_c('div',{staticClass:"flex-between fixed-top-left"},[_vm._v("\n                        "+_vm._s(_vm.event.featured ? "🌟" : "")+"\n                    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-between"},[_c('span',{staticClass:"is-size-6 m-0 p-0 is-uppercase has-text-weight-bold is-subtle"},[_vm._v("\n                            "+_vm._s(_vm.hasDay
                                    ? new Date(
                                          _vm.event.start
                                      ).toLocaleDateString(_vm.$i18n.locale, {
                                          weekday: "short",
                                          month: "short",
                                          day: "numeric",
                                          //   hour: "numeric",
                                          //   minute: "numeric",
                                          //   hour12: true,
                                          //   timeZone: "UTC",
                                          //   timeZoneName: "short",
                                          //   formatMatcher: "best fit",
                                          //   hourCycle: "h24",
                                      })
                                    : "")+"\n                            "+_vm._s(_vm.event.startString)+"\n                            "+_vm._s(_vm.event.category?.value === "courses"
                                    ? ` - ${_vm.event.endString}`
                                    : "")+"\n                        ")]),_vm._v(" "),(
                                _vm.elevated &&
                                // event.start > new Date().toISOString() &&
                                _vm.event.paid &&
                                _vm.event.seats &&
                                _vm.event.seats - _vm.event.seatsTaken === 0
                            )?_c('div',[_c('span',{staticClass:"total-label is-size-6 m-0 p-0 is-uppercase has-text-weight-bold has-text-danger"},[_vm._v(_vm._s(_vm.$t("event.soldout")))])]):_vm._e(),_vm._v(" "),(
                                _vm.elevated &&
                                // event.start > new Date().toISOString() &&
                                _vm.event.paid &&
                                _vm.event.seats &&
                                _vm.event.seats - _vm.event.seatsTaken > 0
                            )?_c('div',[_c('span',{staticClass:"total-label is-size-6 m-0 p-0 is-uppercase has-text-weight-bold has-text-success"},[(
                                        // loadedEvent.seatsTaken &&
                                        _vm.event.seats
                                    )?_c('ICountUp',{attrs:{"delay":500,"startVal":_vm.event.seats,"endVal":_vm.event.seats - _vm.event.seatsTaken,"options":_vm.seatsOptions},on:{"ready":_vm.onReady}}):_vm._e(),_vm._v("\n                                "+_vm._s(_vm.$t("event.shortSeats")))],1)]):_vm._e()]),_vm._v(" "),(_vm.elevated)?_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"is-size-6 is-uppercase has-text-weight-bold is-primary-alt"},[_c('span',{staticClass:"total-label is-size-6 m-0 p-0 is-uppercase has-text-weight-bold has-text-danger"},[(_vm.event.public)?_c('b-icon',{staticClass:"has-text-success",attrs:{"icon-pack":"fas","icon":"fas fa-globe"}}):_vm._e(),_vm._v(" "),(!_vm.event.public)?_c('b-icon',{staticClass:"is-danger",attrs:{"icon-pack":"fas","icon":"fas fa-lock"}}):_vm._e()],1)]),_vm._v(" "),(_vm.elevated)?_c('div',{staticClass:"flex-end"},[_c('div',{staticClass:"is-size-6 is-uppercase has-text-weight-bold is-subtle"}),_vm._v(" "),(_vm.event?.paid)?_c('div',{staticClass:"ml-3 is-size-6 is-uppercase has-text-weight-bold is-subtle"},[_vm._v("\n                                🎟️"+_vm._s(_vm.event.currency)+_vm._s(_vm.event.price)+"\n                            ")]):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"name has-text-weight-bold mt-1 is-size-4 is-uppercase",style:('color:' + _vm.event.backgroundColor + ';')},[_vm._v("\n                        "+_vm._s(_vm.event.title)+"\n                    ")]),_vm._v(" "),(_vm.hasCategory)?_c('h3',{staticClass:"category has-text-weight-bold is-size-7 is-uppercase is-subtle"},[_vm._v("\n                        "+_vm._s(_vm.$i18n.locale === "it"
                                ? _vm.event.category.name
                                : _vm.event.category.nameEn)+"\n                    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card-block"},[(_vm.event.summary && _vm.event.featured)?_c('p',{staticClass:"is-size-6 mt-2 summary-box",domProps:{"innerHTML":_vm._s(_vm.event.summary)}}):_vm._e(),_vm._v(" "),(
                            (_vm.event.summary && _vm.event.featured === false) ||
                            (_vm.event.summary && !_vm.event.featured)
                        )?_c('p',{staticClass:"is-size-6 mt-2 short-summary-box",domProps:{"innerHTML":_vm._s(_vm.event.summary)}}):_vm._e()])])],1):_c('div',{staticClass:"calendar-container"},[_c('figure',{staticClass:"hero-img"},[_c('b-skeleton',{attrs:{"as":"div","width":"100%","height":"100%","animated":"","count":1}})],1),_vm._v(" "),_c('GridBody',{staticClass:"card-body"},[_c('b-skeleton',{attrs:{"as":"div","width":"40%","height":"1rem","animated":"","count":1}}),_vm._v(" "),_c('b-skeleton',{attrs:{"as":"div","width":"100%","height":"2rem","animated":"","count":1}}),_vm._v(" "),_c('b-skeleton',{attrs:{"as":"div","width":"80%","height":"0.75rem","animated":"","count":1}}),_vm._v(" "),_c('p',{staticClass:"subtitle is-size-6 is-text has-text-weight-medium mt-2 is-subtle summary-box"},[_c('b-skeleton',{attrs:{"as":"div","width":"100%","height":"1rem","animated":"","count":1}}),_vm._v(" "),_c('b-skeleton',{attrs:{"as":"div","width":"70%","height":"1rem","animated":"","count":1}})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }